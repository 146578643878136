import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/root/MyNotes-Webapp/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "my-site-link"
    }}>{`My Site Link`}</h2>
    <hr></hr>
    <p><img alt="MyNotes-Webapp.png" src={require("./MyNotes-Webapp.png")} /></p>
    <hr></hr>
    <p>{`部署脚本：`}</p>
    <pre><code parentName="pre" {...{}}>{`#!/usr/bin/env sh

echo $(date)"deploy start!"

# 确保脚本抛出遇到的错误
set -e

# 拉取源代码

cd /root/MyNotes-Webapp
git reset --hard origin/master
git clean -f
git pull
git checkout master

# 构建发布站点页面
cd /root/MyNotes-Webapp
yarn build

# 清除WebPack重复打包文件
cd /root/MyNotes-Webapp/.docz/dist
ls -1 *.js > /tmp/filelist
ls -1 *.js.map >> /tmp/filelist
cat /tmp/filelist | while read eachline
do
  #echo $eachline
  stat=$(cat webpack.stats.json  | grep $eachline | wc -l)
if [ $stat -eq 0 ]; then
  rm -rf $eachline
fi
done

# 推送GitHub Page
echo "bk.chenxixian.cn" > CNAME
# git init  # 初始化后可以注释这行
git add -A
git commit -m "deploy update"
git push -f git@github.com:chenxixian/chenxixian.github.io.git master

# 替换base路径
perl -pi -e "s#base: '/'#base: '/ipns/ipfs.chenxixian.cn/'#gi" /root/MyNotes-Webapp/doczrc.js
# 替换dist路径
perl -pi -e "s#dest: '.docz/dist'#dest: '.docz/ipfsdist'#gi" /root/MyNotes-Webapp/doczrc.js
# 构建IPFS站点页面
cd /root/MyNotes-Webapp
yarn build

# 清除WebPack重复打包文件
cd /root/MyNotes-Webapp/.docz/ipfsdist
ls -1 *.js > /tmp/filelist
ls -1 *.js.map >> /tmp/filelist
cat /tmp/filelist | while read eachline
do
  #echo $eachline
  stat=$(cat webpack.stats.json  | grep $eachline | wc -l)
if [ $stat -eq 0 ]; then
  rm -rf $eachline
fi
done

# IPFS部署
cd /root/MyNotes-Webapp-webhook/
ipd /root/MyNotes-Webapp/.docz/ipfsdist -p pinata -d cloudflare -O

echo $(date)"deploy done!"


`}</code></pre>
    <p>{`使用多个主机提供托管支持：`}</p>
    <p><a parentName="p" {...{
        "href": "https://cvm.chenxixian.cn:8443"
      }}>{`腾讯云托管`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://gateway.pinata.cloud/ipns/ipfs.chenxixian.cn/"
      }}>{`IPFS托管`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://chenxixian-github-io.now.sh"
      }}>{`now托管`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://bk.chenxixian.cn"
      }}>{`GitHub托管`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      